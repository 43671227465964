import { render, staticRenderFns } from "./LinkIcon.vue?vue&type=template&id=796673f0"
import script from "./LinkIcon.vue?vue&type=script&lang=ts"
export * from "./LinkIcon.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/tty215/develop/indent/nola/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('796673f0')) {
      api.createRecord('796673f0', component.options)
    } else {
      api.reload('796673f0', component.options)
    }
    module.hot.accept("./LinkIcon.vue?vue&type=template&id=796673f0", function () {
      api.rerender('796673f0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/atoms/LinkIcon.vue"
export default component.exports