
import Vue, { PropType } from "vue";
import { showOkCancelDialog } from "@/lib/dialog";
import ManuscriptDeleteConfirm, {
  ManuscriptDeleteConfirmProps,
} from "@/components/ui/novel/ManuscriptDeleteConfirm.vue";
import { Dialog } from "@/lib/utils";
import { Manuscript, ManuscriptFolder } from "@/lib/models";
import PizZip from "pizzip";
import { extractParagraphInfo } from "@/lib/docxParser";
import CogIcon from "icons/Cog.vue";
import SimpleDialog, { SimpleDialogProps } from "@/components/ui/dialogs/SimpleDialog.vue";
import ListItem from "@/components/molecules/ListItem.vue";
import SortableList from "@/components/molecules/SortableList.vue";
import { ManuscriptClient } from "@/lib/clients";
import PlusCircleIcon from "icons/PlusCircle.vue";
import RobotIcon from "icons/Robot.vue";
import ViewColumnOutlineIcon from "icons/ViewColumnOutline.vue";
import isMobile from "ismobilejs";

const manuscriptClient = new ManuscriptClient();

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    CogIcon,
    ListItem,
    SortableList,
    PlusCircleIcon,
    RobotIcon,
    ViewColumnOutlineIcon,
  },
  props: {
    novelId: String,
    selected: Object,
    items: Array as PropType<Manuscript[]>,
    folder: Object as PropType<ManuscriptFolder>,
    allFolders: Array as PropType<ManuscriptFolder[]>,
    wordLength: Number,
  },

  data() {
    return {
      manuscripts: this.items,
      isEditMode: false,
      isShowMenu: false,
      isDeleteMode: false,
      isMoveFolderMode: false,
      activeButton: "create",
    };
  },

  watch: {
    items() {
      this.manuscripts = this.items;
    },
  },

  methods: {
    async select(manuscriptKey) {
      const { novelId } = this;

      if (this.isModified()) {
        const yes = await showOkCancelDialog({
          title: "確認",
          content: "保存されていない変更があります。<br />変更を破棄して戻りますか？",
          okButton: "破棄する",
          cancelButton: "キャンセル",
        });
        if (!yes) {
          return;
        }
      }
      this.setModified(false);

      if (manuscriptKey) {
        this.$router.push(
          {
            name: "editor",
            params: {
              novelId,
              manuscriptKey,
            },
          },
          () => {}
        );
      } else {
        this.$router.push(
          {
            name: "editor",
            params: {
              novelId,
            },
          },
          () => {}
        );
      }

      this.$emit("select", manuscriptKey);
    },
    async createManuscript(title = "", content = "") {
      const { novelId, folder } = this;
      const manuscriptFolderId = folder ? folder.manuscriptFolderId : null;

      this.$store.dispatch("manuscriptModule/createManuscript", {
        novelId,
        title,
        content,
        manuscriptFolderId,
        callback: async () => {
          await this.updateManuscriptFolders();

          if (!this.isModified()) {
            this.select(this.createdManuscriptKey);
            this.$store.dispatch("manuscriptModule/initializeCreatedManuscriptKey");
          }

          this.updateOrder();
        },
      });
    },
    async createMultipleManuscript(payload) {
      const { novelId, folder } = this;
      const manuscriptFolderId = folder ? folder.manuscriptFolderId : null;

      const params = payload.map((item) => ({
        novelId,
        title: item.title || "",
        content: item.content || "",
        manuscriptFolderId,
      }));

      await this.$store.dispatch("manuscriptModule/createMultipleManuscript", {
        params,
        callback: async () => {
          await this.updateManuscriptFolders();
        },
      });
    },
    async deleteManuscript(key, title) {
      const { novelId, getEpisodeIdFromNolaNovel } = this;
      const episodeId = getEpisodeIdFromNolaNovel(key);
      const confirmDialog = new Dialog(ManuscriptDeleteConfirm);
      const data: ManuscriptDeleteConfirmProps = {
        title,
        novelId,
        manuscriptId: key,
        episodeId,
      };

      const result = await confirmDialog.show(data);

      if (result) {
        if (!this.isModified()) {
          this.select();
        }

        this.$notify({
          title: "削除しました",
          type: "error",
        });

        this.updateOrder();
        this.updateManuscriptFolders();
      }
    },
    backToFolderList() {
      this.$emit("backToFolderList");
    },
    setFolder(item) {
      this.$emit("clickSetFolder", {
        manuscript: item,
        manuscriptFolder: this.folder,
      });
    },
    previewAll() {
      this.$router.push({ name: "preview" });
    },
    emitCreated() {
      this.$emit("created");
    },

    getManuscript(id) {
      const { getters } = this.$store;
      return getters["manuscriptModule/manuscript"](id);
    },
    getEpisodeIdFromNolaNovel(manuscriptId) {
      const { getManuscript } = this;
      const manuscript = getManuscript(manuscriptId);
      if (!manuscript) {
        return undefined;
      }

      const { associatedData } = manuscript;

      if (!associatedData) {
        return undefined;
      }

      const { nolaNovel } = associatedData;

      if (!nolaNovel) {
        return undefined;
      }

      const { id } = nolaNovel;

      if (!id) {
        return undefined;
      }

      return id;
    },
    onClickImportFile() {
      (this.$refs.input as HTMLInputElement).click();
    },
    async onSelectFile() {
      const fileList = (this.$refs.input as HTMLInputElement).files;

      if (fileList) {
        const files = Array.from(fileList);
        const payloads = [] as { title?: string; content?: string }[];

        const fileReading = files.map(
          (file) =>
            new Promise((resolve) => {
              const reader = new FileReader();
              const isText = file.type === "text/plain";

              reader.onerror = () => {
                resolve(reader.error);
              };

              if (isText) {
                reader.onload = () => {
                  const text = reader.result as string;
                  payloads.push({ title: this.getFileName(file.name), content: text });
                  resolve(reader.result);
                };
                reader.readAsText(file);
              } else {
                reader.onload = () => {
                  const arrayBuffer = reader.result as ArrayBuffer;

                  const zip = new PizZip(arrayBuffer);
                  const xml = zip.file("word/document.xml")!.asText();
                  const dom = new DOMParser().parseFromString(xml, "application/xml");

                  let txt = "";
                  const paragraphs = dom.firstChild!.firstChild!.childNodes;

                  // eslint-disable-next-line no-undef
                  paragraphs.forEach((pNode: ChildNode) => {
                    txt += extractParagraphInfo(pNode);
                  });

                  payloads.push({ title: this.getFileName(file.name), content: txt });
                  resolve(reader.result);
                };
                reader.readAsArrayBuffer(file);
              }
            })
        );

        try {
          this.$emit("changeLoading", true);

          await Promise.all(fileReading);

          await this.createMultipleManuscript(payloads);

          const completeDialog = new Dialog(SimpleDialog);
          const completeData: SimpleDialogProps = {
            title: "インポートが完了しました",
            content:
              "<span>インポートした原稿が作品内に追加されました。</span><br /><span>該当の原稿を開いて確認をお願いいたします。</span>",
            width: 350,
          };
          completeDialog.show(completeData);
        } catch (error) {
          console.error(error);

          const errorDialog = new Dialog(SimpleDialog);
          const errorData: SimpleDialogProps = {
            title: "エラーが発生しました",
            content:
              "<span>原稿のインポートに失敗しました。</span><br /><span>原稿ファイルに問題がないかご確認ください。</span>",
            isError: true,
            width: 350,
          };
          errorDialog.show(errorData);
        } finally {
          this.$emit("changeLoading", false);
        }
      }
    },
    onClickSortManuscript() {
      this.isEditMode = true;
    },
    onClickCloseMenu() {
      this.isShowMenu = false;
    },
    toggleProgressDisplayMode() {
      this.onClickCloseMenu();
      this.$store.dispatch("manuscriptModule/toggleProgressPercentage");
    },
    async updateOrder() {
      if (this.folder) return; // フォルダ内の原稿の順番はフォルダの順番に従うため、ここでは更新しない

      if (this.manuscripts.length !== 0) {
        const order = this.manuscripts.map((manuscript: Manuscript) => manuscript.key);
        await this.$store.dispatch("manuscriptModule/updateOrder", { novelId: this.novelId, order });
      }
    },
    async saveOrder() {
      const { novelId, manuscripts } = this;
      const order = manuscripts.map((item) => item.key);

      if (this.folder) {
        const folders = this.allFolders;
        const manuscriptFolders = folders.map((folder) => {
          const item = folder;
          if (item.manuscriptFolderId === this.folder.manuscriptFolderId) {
            item.manuscriptKeys = order as string[];
          }
          return item;
        });

        await this.$store.dispatch("manuscriptModule/updateFolder", { novelId, manuscriptFolders });
      } else {
        this.updateOrder();
        this.$emit("changeLoading", true);
        this.$store.dispatch("manuscriptModule/initialize", {
          novelId,
          callback: () => {
            this.$emit("changeLoading", false);
          },
        });
      }

      this.isEditMode = false;
      this.isDeleteMode = false;
      this.isMoveFolderMode = false;
    },
    async updateManuscriptFolders() {
      const manuscriptFolders = await manuscriptClient.fetchAllManuscriptFolder(this.novelId);
      await this.$store.dispatch("manuscriptModule/updateFolder", { novelId: this.novelId, manuscriptFolders });
    },
    onClickDeleteManuscript() {
      this.isDeleteMode = true;
    },
    onClickMoveManuscript() {
      this.isMoveFolderMode = true;
    },
    async selectButton(buttonName) {
      this.activeButton = buttonName;

      if (buttonName === "ai") {
        if (this.isModified()) {
          const yes = await showOkCancelDialog({
            title: "確認",
            content: "保存されていない変更があります。<br />変更を破棄して戻りますか？",
            okButton: "破棄する",
            cancelButton: "キャンセル",
          });
          if (!yes) {
            this.activeButton = "create";
            return;
          }
        }
        this.setModified(false);
        this.$emit("aiFeedback");
      } else {
        this.$emit("notAiFeedback");
      }
    },
    clearActiveButton() {
      this.activeButton = "create";
    },
  },

  computed: {
    totalWordLength() {
      let total: number = 0;
      if (this.items) {
        this.items.forEach((x) => {
          total += this.contentLength(x.content);
        });
      }
      return total;
    },
    remainingProgress() {
      const { wordLength, totalWordLength } = this;
      if (wordLength && totalWordLength) {
        return Math.floor((1 - totalWordLength / wordLength) * 100);
      }
      return "--";
    },
    unitProgress() {
      return (content) => {
        const { wordLength } = this;
        if (content && wordLength) {
          if (this.isProgressPercentage) {
            return `${Math.floor((this.contentLength(content) / wordLength) * 100)}%`;
          }

          return `${this.contentLength(content)}字`;
        }
        return "--";
      };
    },
    contentLength() {
      return (content: string) => {
        let rubyLength = 0;

        // eslint-disable-next-line no-control-regex
        const rubyRegExp = new RegExp("[|｜][^|｜\r\n]*?《.*?》", "gu");
        const textWithRuby = content.match(rubyRegExp);
        if (textWithRuby) {
          textWithRuby.forEach((text) => {
            const onlyRubyRegExp = new RegExp("《.*?》$");
            const textOnlyRuby = text.match(onlyRubyRegExp);
            rubyLength += textOnlyRuby![0].length + 1; // 親文字の縦棒をカウントから除くための+1;
          });
        }

        let contentLength = 0;
        switch (this.countType) {
          case "addBlank":
            contentLength = content.replace(/\n/g, "").length;
            break;
          case "addNewLine":
            contentLength = content.replace(/[^\S\n]/g, "").length;
            break;
          case "all":
            contentLength = content.length;
            break;
          case "onlyCharacter":
          default:
            contentLength = content.replace(/\s+|\n/g, "").length;
            break;
        }

        return contentLength - rubyLength;
      };
    },
    createdManuscriptKey() {
      return this.$store.getters["manuscriptModule/createdManuscriptKey"];
    },
    countType() {
      return this.$store.getters["manuscriptModule/countType"];
    },
    getFileName() {
      return (fileName: string) => {
        const array = fileName.split(".");
        array.pop(); // 拡張子を削除
        return array.join(".");
      };
    },
    progressChipMinWidthValue() {
      return (content: string) => {
        const baseWidth = 40;
        const widthPerChar = 8;

        if (this.isProgressPercentage) {
          return `${baseWidth}px`; // パーセント表示の場合は固定値
        }
        // 文字数表示の場合
        const contentDigit = String(content.length).length;
        // 3桁以内ならbaseWidth、4桁以降は桁数ごとにwidthPerCharを追加
        const additionalWidth = contentDigit > 3 ? (contentDigit - 3) * widthPerChar : 0;
        return `${baseWidth + additionalWidth}px`;
      };
    },
    isProgressPercentage() {
      return this.$store.getters["manuscriptModule/progressPercentage"];
    },
    foldersBelongManuscript() {
      return (manuscriptKey: string) => this.$store.getters["manuscriptModule/foldersBelongManuscript"](manuscriptKey);
    },
    folderName() {
      return (item) => {
        if (item.key !== undefined) {
          const folders = this.foldersBelongManuscript(item.key);
          return folders.reduce((acc, cur) => (acc ? `${acc} / ${cur.name}` : `${cur.name}`), "");
        }
        return "";
      };
    },
    isMobileDevice() {
      return isMobile().phone || (isMobile().tablet && window.innerWidth < 1024);
    },
  },
});

interface Props {
  novelId: string;
  selected: any;
  items: any[];
  folder: any;
  allFolders: ManuscriptFolder[];
  wordLength: number;
}

interface Data {
  manuscripts: Manuscript[];
  isEditMode: boolean;
  isShowMenu: boolean;
  isDeleteMode: boolean;
  isMoveFolderMode: boolean;
  activeButton: string;
}

interface Methods {
  select: (manuscriptKey?: string) => Promise<void>;
  createManuscript: (title?: string, content?: string) => Promise<void>;
  createMultipleManuscript: (payload: { title?: string; content?: string }[]) => Promise<void>;
  deleteManuscript: (key: string, title: string) => Promise<void>;
  backToFolderList: () => void;
  setFolder: (item: object) => void;
  previewAll: () => void;
  emitCreated(): void;
  getManuscript(manuscriptId: string): Manuscript | undefined;
  getEpisodeIdFromNolaNovel(manuscriptId: string): string | undefined;
  onClickImportFile(): void;
  onSelectFile(): Promise<void>;
  onClickSortManuscript(): void;
  onClickCloseMenu(): void;
  toggleProgressDisplayMode(): void;
  updateOrder(): void;
  saveOrder(): void;
  updateManuscriptFolders(): void;
  onClickDeleteManuscript(): void;
  onClickMoveManuscript(): void;
  selectButton(buttonName: string): void;
  clearActiveButton(): void;
}

interface Computed {
  totalWordLength: number;
  remainingProgress: number | string;
  unitProgress: (content: string) => number | string;
  contentLength: (content: string) => number;
  createdManuscriptKey: string;
  countType: string;
  getFileName: (fileName: string) => string;
  progressChipMinWidthValue: (content: string) => string;
  isProgressPercentage: boolean;
  foldersBelongManuscript: (manuscriptKey: string) => ManuscriptFolder[];
  folderName: (item: Manuscript) => string;
  isMobileDevice: boolean;
}
