
import Vue, { PropType } from "vue";
import { Mode, Plot, PlotGroups, Plots, SubPlot, UpdatePlotModePayload, UpdateSubPlotPayload } from "@/lib/models/plot";
import { Plan } from "@/lib/models/user";
// NOTE: 機能紹介GIFを修正するため一時的にコメントアウト
// import { Dialog } from "@/lib/utils";
// import PremiumFeatureDialog, { PremiumFeatureDialogProps } from "@/components/ui/PremiumFeatureDialog.vue";
// import { downloadPublicImage } from "@/lib/s3";

export default Vue.extend<Data, Methods, Computed, Props>({
  props: {
    novelId: String,
    plotId: String,
    plot: Object as PropType<Plot | SubPlot>,
  },
  computed: {
    currentMode() {
      const { mode } = this.$route.query;
      return mode as Mode;
    },
    plan() {
      return this.$store.getters["userModule/plan"] as Plan;
    },
    isFree() {
      return this.plan === Plan.free;
    },
    convertPlot() {
      return (plots, mode) => {
        const originalPlot = { description: "", plots: [] as Plots[] };
        plots.plotGroups.forEach((plotArray) => {
          originalPlot.description += `【${plotArray.name}】\n${plotArray.description}\n`;
          plotArray.plots.forEach((plot) => {
            originalPlot.plots.push(plot);
          });
        });
        switch (mode) {
          case Mode.kishoutenketsu: {
            const KSTKArray: PlotGroups[] = [
              {
                name: "起",
                description: originalPlot.description,
                plots: originalPlot.plots,
              },
              { name: "承", description: "", plots: [] },
              { name: "転", description: "", plots: [] },
              { name: "結", description: "", plots: [] },
            ];
            return KSTKArray;
          }
          case Mode.johakyu: {
            const JHQArray: PlotGroups[] = [
              {
                name: "序",
                description: originalPlot.description,
                plots: originalPlot.plots,
              },
              { name: "破", description: "", plots: [] },
              { name: "急", description: "", plots: [] },
            ];
            return JHQArray;
          }
          default:
            // customの場合を想定、何も変更しないで返す
            return plots.plotGroups;
        }
      };
    },
  },
  methods: {
    async changePlotMode(mode) {
      const { $store, $router, novelId, plotId, plot, isFree, convertPlot } = this;
      const { dispatch } = $store;

      if (isFree && mode !== Mode.kishoutenketsu) {
        // NOTE: 機能紹介GIFを修正するため一時的にコメントアウト
        // const premiumFeatureDialog = new Dialog(PremiumFeatureDialog);
        // const data: PremiumFeatureDialogProps = {
        //   title: "プロットのモード変更はプレミアム機能です",
        //   text: "Nolaプレミアムにご登録いただくとプロットを『序破急』や『起承転承転結』などのお好きな形式に変更ができます。より複雑なストーリーの組み立てや長編作品・シリーズ作品の管理にご活用ください。",
        //   image: (await downloadPublicImage("shared/images/gif/plot_mode_change.gif")) as string,
        // };
        // premiumFeatureDialog.show(data);

        $router.push({ name: "subscriptionAnnounce", query: { from: "changePlotMode" } });

        return;
      }

      const plotGroups = convertPlot(plot, mode);

      if (plotId) {
        const updateSubPlotPayload: UpdateSubPlotPayload = {
          novelId,
          plotId,
          name: (plot as SubPlot).name,
          plotGroups,
          mode,
          layout: plot.layout,
          displayFormat: plot.displayFormat,
        };
        dispatch("plotModule/updateSubPlot", updateSubPlotPayload);
      } else {
        const updatePlotModePayload: UpdatePlotModePayload = {
          novelId,
          plotGroups,
          mode,
          layout: plot.layout,
          displayFormat: plot.displayFormat,
        };
        dispatch("plotModule/updatePlotMode", updatePlotModePayload);
      }

      const pageName = mode === Mode.custom ? "plotEdit" : "plot";
      if (plotId) {
        $router.push({ name: pageName, params: { novelId, plotId } });
      } else {
        $router.push({ name: pageName, params: { novelId } });
      }
    },
  },
});

interface Props {
  novelId: string;
  plotId?: string;
  plot: Plot | SubPlot;
}

interface Data {}

interface Computed {
  currentMode: Mode;
  plan: Plan;
  isFree: boolean;
  convertPlot(plots: Plot, mode: Mode): PlotGroups[];
}

interface Methods {
  changePlotMode(mode: Mode): void;
}
