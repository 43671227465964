
import Vue from "vue";
import { Manuscript, ManuscriptSetting, Novel, Plan, User } from "@/lib/models";
import RadioIcon from "@/components/atoms/RadioIcon.vue";
import CheckboxIcon from "@/components/atoms/CheckboxIcon.vue";
import { Dialog } from "@/lib/utils";
import DialogVue from "@/components/ui/Dialog.vue";
import MaskedLoading from "@/components/atoms/MaskedLoading.vue";
import { generateDocx } from "@/lib/utils/generator/docx";
import axiosBase from "axios";
import { saveAs } from "file-saver";
import PizZip from "pizzip";
import { fetchS3BinaryData } from "@/lib/s3";
import ManuscriptExportingDialog from "@/components/ui/ManuscriptExportingDialog.vue";

const axios = axiosBase.create({
  baseURL: process.env.VUE_APP_IMAGE_DOWNLOAD_API_ENDPOINT,
  headers: { "Content-Type": "application/json" },
});

const FileType = {
  PDF: "pdf",
  TXT: "txt",
  WORD: "word",
} as const;
type TFileType = typeof FileType[keyof typeof FileType];

const WritingFormat = {
  VERTICAL: "vertical",
  HORIZONTAL: "horizontal",
} as const;
type TWritingFormat = typeof WritingFormat[keyof typeof WritingFormat];

const ExportType = {
  ZIP: "zip",
  FILE: "file",
} as const;
type TExportType = typeof ExportType[keyof typeof ExportType];

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { RadioIcon, CheckboxIcon, MaskedLoading },
  props: {
    novelId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectedManuscripts: [],
      selectedFileType: FileType.PDF,
      selectedShowTitle: true,
      selectedFormat: WritingFormat.VERTICAL,
      selectedExportType: ExportType.ZIP,
      checkBoxColor: "#707070",
      isDownloading: false,
      selectedShowNovelTitle: true,
      selectedManuscriptPaperBorder: true,
      selectedManuscriptPageNumber: true,
      selectedManuscriptPageSettings: true,
      selectedNolaBrand: true,
    };
  },
  methods: {
    onClickOutSide() {
      this.$close(true);
    },
    onChangeFileType(fileType) {
      this.selectedFileType = fileType;
    },
    onChangeShowTitle(showTitle) {
      this.selectedShowTitle = showTitle;
    },
    onChangeFormat(format) {
      this.selectedFormat = format;
    },
    onChangeExportType(exportType) {
      this.selectedExportType = exportType;
    },
    onChangeShowNovelTitle(showNovelTitle) {
      this.selectedShowNovelTitle = showNovelTitle;
    },
    onChangeManuscriptPaperBorder(showManuscriptPaperBorder) {
      this.selectedManuscriptPaperBorder = showManuscriptPaperBorder;
    },
    onChangeManuscriptPageNumber(showManuscriptPageNumber) {
      this.selectedManuscriptPageNumber = showManuscriptPageNumber;
    },
    onChangeManuscriptPageSettings(showManuscriptPageSettings) {
      this.selectedManuscriptPageSettings = showManuscriptPageSettings;
    },
    onChangeNolaBrand(showNolaBrand) {
      this.selectedNolaBrand = showNolaBrand;
    },
    onClickManuscript(manuscript) {
      if (this.selectedManuscripts.some((x) => x.key === manuscript.key)) {
        this.selectedManuscripts = this.selectedManuscripts.filter((x) => x.key !== manuscript.key);
      } else {
        this.selectedManuscripts.push(manuscript);
      }
    },
    async onClickDownload() {
      this.isDownloading = true;
      const zip = new PizZip();

      try {
        const manuscripts = this.selectedManuscripts;
        const fileType = this.selectedFileType;
        const showTitle = this.selectedShowTitle;
        const format = this.selectedFormat;
        const exportType = this.selectedExportType;
        const showNovelTitle = this.selectedShowNovelTitle;
        const showManuscriptPaperBorder = this.selectedManuscriptPaperBorder;
        const showManuscriptPageNumber = this.selectedManuscriptPageNumber;
        const showManuscriptPageSettings = this.selectedManuscriptPageSettings;
        const showNolaBrand = this.selectedNolaBrand;
        const { manuscriptSetting, userId, novelId, novelTitle } = this;

        switch (fileType) {
          case FileType.PDF: {
            try {
              // PDF出力時はローディング・本コンポーネントを非表示にし、エクスポート中ダイアログを表示する
              this.isDownloading = false;
              this.onClickOutSide();
              const manuscriptExportingDialog = new Dialog(ManuscriptExportingDialog);
              manuscriptExportingDialog.show();

              // PDFファイルの作成・S3へのアップロード
              const response = await axios.post("/invokeGenerateManuscriptPdf", {
                manuscripts,
                format,
                showTitle,
                manuscriptSetting,
                userId,
                novelId,
                ...(this.isVerticalFormat && {
                  verticalFormatOptions: {
                    showNovelTitle,
                    novelTitle,
                    showManuscriptPaperBorder,
                    showManuscriptPageNumber,
                    showManuscriptPageSettings,
                    showNolaBrand,
                  },
                }),
              });

              // S3にアップロードされるPDFのS3キー(novels/以下のパス)を取得
              const { s3Key } = response.data;

              // サーバー側でPDFがS3へアップロードされるまでポーリングし、ダウンロードする
              const fileName = this.fileName("pdf");
              await this.pollForPdf(s3Key, fileName, userId, novelId);
            } catch (error) {
              console.error("PDFのダウンロード中にエラーが発生しました:", error);
              this.$store.commit("manuscriptModule/setExportCompleted", true); // エラー時には出力中ダイアログを閉じる
              throw error;
            }

            break;
          }

          case FileType.TXT: {
            switch (exportType) {
              case ExportType.ZIP: {
                // txtファイルをzipファイルにまとめる
                manuscripts.forEach((manuscript) => {
                  const title = manuscript.title || "(タイトル未設定)";
                  const content = `${showTitle ? `${title}\n\n` : ""}${manuscript.content}`;
                  zip.file(`${title}.txt`, content);
                });

                const zipFile = zip.generate({ type: "blob" });
                saveAs(zipFile, `${this.novelTitle}.zip`);

                break;
              }

              case ExportType.FILE: {
                // 原稿を1つのテキストにまとめる
                const contents = manuscripts
                  .map(
                    (manuscript) =>
                      `${showTitle ? `${manuscript.title || "(タイトル未設定)"}\n\n` : ""}${manuscript.content}`
                  )
                  .join("\n\n");

                // テキストファイルの作成
                const txtFile = new Blob([contents], { type: "text/plain" });

                const fileName = this.fileName("txt");
                saveAs(txtFile, fileName);

                break;
              }

              default:
                break;
            }

            break;
          }

          case FileType.WORD: {
            switch (exportType) {
              case ExportType.ZIP: {
                const promiseArray = manuscripts.map(async (manuscript) => {
                  const title = manuscript.title || "(タイトル未設定)";
                  // Wordファイルの作成
                  const docx = await generateDocx([manuscript], showTitle);

                  // BlobをArrayBufferに変換
                  const arrayBuffer = await this.blobToArrayBuffer(docx);

                  zip.file(`${title}.docx`, arrayBuffer);
                });
                await Promise.all(promiseArray);

                const zipFile = zip.generate({ type: "blob" });
                saveAs(zipFile, `${this.novelTitle}.zip`);

                break;
              }

              case ExportType.FILE: {
                // Wordファイルの作成
                const docx = await generateDocx(manuscripts, showTitle);

                const fileName = this.fileName("docx");
                saveAs(docx, fileName);

                break;
              }

              default:
                break;
            }
            break;
          }

          default:
            throw new Error("invalid FileType");
        }
      } catch (e) {
        const confirmDialog = new Dialog(DialogVue);
        const options = {
          title: "エラー",
          content: e,
        };
        confirmDialog.show(options);
      } finally {
        this.isDownloading = false;
      }
    },
    blobToArrayBuffer(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result as ArrayBuffer);
        reader.onerror = reject;
        reader.readAsArrayBuffer(blob);
      });
    },
    showPremiumFeatureDialog() {
      this.$close(true);
      this.$router.push({ name: "subscriptionAnnounce", query: { from: "editor" } });
    },
    /** PDFデータをユーザー端末に保存する */
    async downloadPdf(pdfData: Buffer, fileName: string) {
      try {
        const blob = new Blob([new Uint8Array(pdfData)], { type: "application/pdf" });
        saveAs(blob, fileName);
      } catch (error) {
        console.error("PDFのダウンロード中にエラーが発生しました:", error);
      }
    },
    /** PDFをS3からダウンロードするまでポーリングするメソッド */
    async pollForPdf(s3Key: string, fileName: string, userId: string, novelId: string): Promise<void> {
      const maxAttempts = 450; // 最大試行回数
      const delay = 2000; // 2秒間隔でポーリング
      let attempts = 0;

      const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

      while (attempts < maxAttempts) {
        if (this.isExportCancelled) {
          this.$store.commit("manuscriptModule/setExportCancelled", false);
          return;
        }

        attempts += 1;

        /* eslint-disable no-await-in-loop */
        try {
          // S3からPDFデータを取得
          const pdfDataBuffer = await fetchS3BinaryData(s3Key);

          if (pdfDataBuffer) {
            // PDFが存在すればダウンロード
            await this.downloadPdf(pdfDataBuffer, fileName);
            this.$store.commit("manuscriptModule/setExportCompleted", true);

            // S3のprivate/${userId}/novels/${novelId}/tmp/以下のPDFオブジェクトを削除
            axios.post("/deleteManuscriptPdf", {
              userId,
              novelId,
              s3Key,
            });
            return; // 成功、ポーリング終了
          }
        } catch (error) {
          console.error("PDFの利用確認中にエラーが発生しました:", error);
        }

        // 次の試行まで待機
        await wait(delay);
      }

      // 最大試行回数を超えた場合は例外をスローして処理を終了
      throw new Error("原稿のエクスポートに失敗しました。しばらく待ってから再実施してください。");
    },
  },
  computed: {
    novelTitle() {
      const novel = this.$store.getters["novelModule/novel"](this.novelId) as Novel;
      return novel.title;
    },
    manuscriptList() {
      return this.$store.getters["manuscriptModule/manuscriptList"];
    },
    manuscriptSetting() {
      return this.$store.getters["novelModule/manuscriptSetting"](this.novelId) as ManuscriptSetting;
    },
    isChecked() {
      return (manuscript) => {
        const { selectedManuscripts } = this;
        return selectedManuscripts.some((x) => x.key === manuscript.key);
      };
    },
    orderNumber() {
      return (manuscript) => {
        const { selectedManuscripts } = this;
        return selectedManuscripts.findIndex((x) => x.key === manuscript.key) + 1;
      };
    },
    fileName() {
      return (extension) => {
        const { novelTitle, selectedManuscripts } = this;
        const firstManuscriptName = selectedManuscripts[0].title || "(タイトル未設定)";
        const isMultiple = selectedManuscripts.length > 1;
        return `${novelTitle}_${firstManuscriptName}${isMultiple ? "（複数原稿選択）" : ""}.${extension}`;
      };
    },
    plan() {
      return this.$store.getters["userModule/plan"] as Plan;
    },
    isFree() {
      return this.plan === Plan.free;
    },
    isVerticalFormat() {
      return this.selectedFormat === WritingFormat.VERTICAL;
    },
    userId() {
      const { userId } = this.$store.getters["userModule/user"] as User;
      return userId;
    },
    isExportCancelled() {
      return this.$store.getters["manuscriptModule/isExportCancelled"];
    },
  },
});

interface Props {
  novelId: string;
}

interface Data {
  selectedManuscripts: Manuscript[];
  selectedFileType: TFileType;
  selectedShowTitle: boolean;
  selectedFormat: TWritingFormat;
  selectedExportType: TExportType;
  checkBoxColor: string;
  isDownloading: boolean;
  selectedShowNovelTitle: boolean;
  selectedManuscriptPaperBorder: boolean;
  selectedManuscriptPageNumber: boolean;
  selectedManuscriptPageSettings: boolean;
  selectedNolaBrand: boolean;
}

interface Methods {
  onClickOutSide: () => void;
  onChangeFileType(fileType: TFileType): void;
  onChangeShowTitle(showTitle: boolean): void;
  onChangeFormat(format: TWritingFormat): void;
  onChangeExportType(exportType: TExportType): void;
  onClickManuscript(manuscript: Manuscript): void;
  onClickDownload(): void;
  blobToArrayBuffer(blob: Blob): Promise<ArrayBuffer>;
  onChangeShowNovelTitle(showNovelTitle: boolean): void;
  onChangeManuscriptPaperBorder(showManuscriptPaperBorder: boolean): void;
  onChangeManuscriptPageNumber(showManuscriptPageNumber: boolean): void;
  onChangeManuscriptPageSettings(showManuscriptPageSettings: boolean): void;
  onChangeNolaBrand(showNolaBrand: boolean): void;
  showPremiumFeatureDialog(): void;
  downloadPdf(pdfData: Buffer, fileName: string): void;
  pollForPdf(s3Key: string, fileName: string, userId: string, novelId: string): Promise<void>;
}

interface Computed {
  novelTitle: string;
  manuscriptList: Manuscript[];
  manuscriptSetting: ManuscriptSetting;
  isChecked(manuscript: Manuscript): boolean;
  orderNumber(manuscript: Manuscript): number;
  fileName(extension: string): string;
  plan: Plan;
  isFree: boolean;
  isVerticalFormat: boolean;
  userId: string;
  isExportCancelled: boolean;
}

export type ExportManuscriptDialogProps = Props;
