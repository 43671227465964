var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "root" },
    [
      _c(
        "list-templates",
        { attrs: { active: !!_vm.manuscriptKey } },
        [
          _vm.isShowFolderList
            ? _c(
                "div",
                { attrs: { slot: "side" }, slot: "side" },
                [
                  _c("manuscript-folder-list", {
                    attrs: {
                      "novel-id": _vm.novelId,
                      items: _vm.manuscriptFolders,
                      manuscriptsCount: _vm.manuscriptsCount,
                      isInsert: _vm.isFolderInsertMode,
                      selectedManuscript: _vm.targetManuscript,
                      selectedFolderId: _vm.selectedFolderId,
                    },
                    on: {
                      selectedFolder: _vm.selectFolder,
                      clickCompleteSetFolder: function ($event) {
                        return _vm.finishFolderSelect()
                      },
                    },
                  }),
                ],
                1
              )
            : _c(
                "div",
                {
                  staticClass: "manuscript-list",
                  attrs: { slot: "side" },
                  slot: "side",
                },
                [
                  _c("manuscript-list", {
                    ref: "manuscriptList",
                    attrs: {
                      "novel-id": _vm.novelId,
                      items: _vm.manuscriptItems,
                      selected: _vm.selected,
                      folder: _vm.manuscriptFolder,
                      allFolders: _vm.manuscriptFolders,
                      wordLength: _vm.wordLength,
                    },
                    on: {
                      select: _vm.handleSelectItem,
                      backToFolderList: function ($event) {
                        return _vm.showFolderList()
                      },
                      clickSetFolder: _vm.setFolder,
                      changeLoading: _vm.changeLoading,
                      aiFeedback: _vm.aiFeedback,
                      notAiFeedback: _vm.notAiFeedback,
                    },
                  }),
                ],
                1
              ),
          _vm.selected && !_vm.aiFeedbackMode
            ? _c("manuscript-editor", {
                key: _vm.selected.key,
                ref: "editor",
                attrs: {
                  slot: "main",
                  "novel-id": _vm.novelId,
                  manuscriptKey: _vm.selected.key,
                },
                on: { commentIconClicked: _vm.handleCommentIconClick },
                slot: "main",
              })
            : _vm._e(),
          _vm.aiFeedbackMode
            ? _c("manuscript-analysis", {
                ref: "manuscriptAnalysis",
                attrs: {
                  slot: "main",
                  novelId: _vm.novelId,
                  manuscriptList: _vm.manuscripts,
                },
                on: { closeAiFeedback: _vm.closeAiFeedback },
                slot: "main",
              })
            : _vm._e(),
        ],
        1
      ),
      _c("slide-shared-link-menu", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isEnableMobile && !_vm.aiFeedbackMode,
            expression: "isEnableMobile && !aiFeedbackMode",
          },
        ],
        ref: "slideMenu",
        staticClass: "slide-menu",
        attrs: { novelId: _vm.novelId, kind: "manuscript" },
      }),
      _vm.isShowPublishSharedLink
        ? _c(
            "div",
            { staticClass: "dialog" },
            [
              _c("publish-shared-link", {
                staticClass: "dialog-content",
                attrs: { novelId: _vm.novelId, kind: "manuscript" },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.loading ? _c("masked-loading", { staticClass: "loading" }) : _vm._e(),
      _vm.showNolaOnboarding
        ? _c("FirstNolaOnboarding", {
            on: {
              "tutorial-completed": _vm.onTutorialCompleted,
              "tutorial-closed": _vm.onTutorialClosed,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }