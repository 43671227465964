var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _setup.isVisible
        ? _c(_setup.Onboarding, {
            attrs: {
              targetSelector: ".side-menu-novel-root",
              message: _setup.message,
              canOutsideClick: true,
              tooltipPosition: "right-top",
              maxWidth: "460px",
              tooltipPadding: "14px 20px",
              tooltipBorderRadius: "15px",
              isDisplayedCloseText: false,
              triangleOffset: -110,
            },
            on: { "onboarding-outside-clicked": _setup.closeOnboarding },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }