var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "message-box dialog-mask" }, [
    _c(
      "div",
      { staticClass: "dialog-box" },
      [
        [
          _c("div", { staticClass: "dialog-body" }, [
            _c("h1", [_vm._v("現在、出力データを準備しています")]),
            _c(
              "div",
              { staticClass: "loading-container" },
              [
                _c("lottie", {
                  attrs: {
                    animationData: require("@/assets/img/manuscript-analysis/loading.json"),
                  },
                }),
              ],
              1
            ),
            _vm._m(0),
          ]),
          _c("footer", [
            _c(
              "button",
              {
                staticClass: "button primary",
                on: { click: _vm.onCancelExportButtonClick },
              },
              [_vm._v("出力を中断する")]
            ),
            _c("div", { staticClass: "note" }, [
              _vm._v("※ 上記ボタンを押すとエクスポートが中断されます"),
            ]),
          ]),
        ],
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "text-container" }, [
      _vm._v(" 50万字以上のエクスポートには"),
      _c("span", { staticClass: "primary" }, [_vm._v("数分程度")]),
      _vm._v("お時間がかかる場合がございます。 "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }