
import Vue from "vue";
import Onboarding from "@/components/molecules/OnBoarding.vue";
import { NolaAnalytics, NolaContentType, NolaItemId } from "@/lib/utils/analytics";

interface StepData {
  targetSelector: string;
  optionTargetSelector?: string;
  message: string;
  tooltipPosition: string;
  closeText: string;
  round: boolean;
  offsetX?: number;
  highlightPadding: object;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { Onboarding },
  data() {
    return {
      isVisible: true,
      currentStepIndex: 0,
      steps: [
        {
          targetSelector: ".tutorial-novel-top",
          optionTargetSelector: ".tutorial-novel-bottom",
          message:
            "<div style='font-size:16px;margin-bottom:16px;'>ここから執筆に必要な機能を利用することができます。<br>テストデータが入っていますので、参考までにぜひご覧ください&#x1F408;&#x200D;&#x2B1B;&#x1F4D6;</div>",
          tooltipPosition: "right-top",
          highlightPadding: { top: 0, bottom: 0, left: 0, right: 0 },
          closeText: "次へ(1/2)",
          offsetX: 0,
          round: false,
        },
        {
          targetSelector: ".tutorial-back-home",
          optionTargetSelector: "",
          message:
            "<div style='font-size:16px;margin-bottom:10px;'>各機能を確認したら、ここからトップページに<br>戻って執筆を開始しましょう&#x1F58B;</div>",
          tooltipPosition: "bottom-left",
          highlightPadding: { top: 2, bottom: 4, left: 6, right: 6 },
          closeText: "閉じる",
          offsetX: 20,
          round: false,
        },
      ] as StepData[],
      nolaAnalytics: new NolaAnalytics(this.$gtm, this.$store),
    };
  },
  computed: {
    currentStepData() {
      return this.steps[this.currentStepIndex] || null;
    },
  },
  methods: {
    nextStep() {
      if (this.currentStepIndex < this.steps.length - 1) {
        this.currentStepIndex += 1;
        this.nolaAnalytics.logViewItemEvent(NolaItemId.EditorOnboardingBack, NolaContentType.Onboarding);
      } else {
        this.isVisible = false;
        this.$emit("tutorial-completed");
      }
    },
    closeOnboarding() {
      // 次へボタンのクリック
      if (this.currentStepIndex === 0) {
        this.nolaAnalytics.logButtonEvent(NolaItemId.EditorOnboardingNext);
      } else {
        // 閉じるボタンのクリック
        this.nolaAnalytics.logButtonEvent(NolaItemId.EditorOnboardingClose);
      }
    },
    outsideClicked() {
      this.nolaAnalytics.logButtonEvent(NolaItemId.EditorOnboardingOutside);
    },
  },
});

interface Props {}

interface Data {
  isVisible: boolean;
  currentStepIndex: number;
  steps: StepData[];
  nolaAnalytics: NolaAnalytics;
}

interface Computed {
  currentStepData: StepData | null;
}

interface Methods {
  nextStep(): void;
  closeOnboarding(): void;
  outsideClicked(): void;
}
