var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.isVisible && _vm.currentStepData
        ? _c("Onboarding", {
            key: _vm.currentStepIndex,
            attrs: {
              targetSelector: _vm.currentStepData?.targetSelector,
              optionTargetSelector: _vm.currentStepData?.optionTargetSelector,
              message: _vm.currentStepData?.message,
              closeText: _vm.currentStepData?.closeText,
              tooltipPosition: _vm.currentStepData?.tooltipPosition,
              round: _vm.currentStepData?.round,
              offsetX: _vm.currentStepData?.offsetX,
              canOutsideClick: true,
              highlightPadding: _vm.currentStepData?.highlightPadding,
              tooltipPadding: "14px 20px",
              tooltipBorderRadius: "15px",
            },
            on: {
              "onboarding-outside-clicked": _vm.outsideClicked,
              "onboarding-finished": _vm.nextStep,
              "onboarding-close-clicked": _vm.closeOnboarding,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }