
import Vue from "vue";

export default Vue.extend({
  props: {
    active: String,
    hidden: Boolean,
  },
  computed: {
    items() {
      return [
        {
          to: "characters",
          description: "登場人物",
        },
        {
          to: "worldViews",
          description: "世界観",
        },
      ];
    },
  },
});
