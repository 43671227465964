
import Vue from "vue";
import Lottie from "@/components/animations/Lottie.vue";

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    Lottie,
  },
  methods: {
    onCancelExportButtonClick() {
      this.$store.commit("manuscriptModule/setExportCancelled", true);
      this.$close(true);
    },
  },
  computed: {
    isExportCompleted() {
      return this.$store.getters["manuscriptModule/isExportCompleted"];
    },
  },
  watch: {
    isExportCompleted(newVal) {
      if (newVal) {
        this.$store.commit("manuscriptModule/setExportCompleted", false);
        this.$close();
      }
    },
  },
});

interface Data {}

interface Methods {
  onCancelExportButtonClick: () => void;
}

interface Props {
  novelTitle: string;
}

interface Computed {
  isExportCompleted: boolean;
}
