
import Vue from "vue";
import TextField from "@/components/molecules/TextField.vue";
import ImageSelector from "@/components/molecules/ImageSelector.vue";
import SelectBox from "@/components/molecules/SelectBox.vue";
import MultipleSelectBox from "@/components/molecules/MultipleSelectBox.vue";
import { submitImage } from "@/lib/image-save";
import { genre as genreList, category as categoryList, workStatus as workStatusList } from "@/lib/novelSettings";
import { Novel } from "@/lib/models";
import { Dialog } from "@/lib/utils";
import DialogVue from "@/components/ui/Dialog.vue";
import isMobile from "ismobilejs";
import { handleShortcutKeys, TriggerKey } from "@/lib/utils/keyboardShortcuts";
import Onboarding from "@/components/molecules/OnBoarding.vue";
import { NovelSetting } from "@/lib/models/novel";

type NovelParam = {
  id: string;
  name: string;
  image: string;
};

interface Data {
  novel: Novel | null;
  image?: string;
  genre?: NovelParam | null;
  genreList: NovelParam[];
  category?: NovelParam[] | null;
  categoryList: NovelParam[];
  workStatus?: NovelParam | null;
  workStatusList: NovelParam[];
  unbindShortcutKeys: (() => void) | null;
  showFirstWorkStatusHighlight: boolean;
}

interface Methods {
  change(): void;
  initialize(): void;
  imagePath(): string | null;
  submit(): Promise<void>;
  selectGenre(item: NovelParam): void;
  selectCategory(items: NovelParam[]): void;
  selectWorkStatus(item: NovelParam): void;
  unselectGenre(): void;
  showErrorDialog(message: string): void;
  onboardingOutsideClicked(): void;
}

interface Props {
  novelId: string;
}

export default Vue.extend<Data, Methods, unknown, Props>({
  components: {
    TextField,
    ImageSelector,
    SelectBox,
    MultipleSelectBox,
    Onboarding,
  },
  props: {
    novelId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      novel: null,
      image: undefined,
      genre: null,
      category: null,
      workStatus: workStatusList.find((x) => x.id === "inProgress"),
      genreList,
      categoryList,
      workStatusList,
      unbindShortcutKeys: null,
      showFirstWorkStatusHighlight: false,
    };
  },
  async created() {
    const { initialize } = this;
    initialize();
  },
  beforeDestroy() {
    // コンポーネントが破棄される前に保存ボタンのショートカットキーを解除
    if (this.unbindShortcutKeys) this.unbindShortcutKeys();
  },
  computed: {
    isPC() {
      return !isMobile().any;
    },
    hasDisplayedWorkStatusHighlightPopup() {
      return this.$store.getters["generalModule/hasDisplayedWorkStatusHighlightPopup"];
    },
    currentWorkStatus(): NovelSetting {
      const { novel } = this;
      return workStatusList.find((status) => status.id === novel.workStatus) || workStatusList[0];
    },
    workStatusIsNotCompleted() {
      return this.currentWorkStatus.id !== "completed";
    },
  },
  methods: {
    async initialize() {
      const { novelId, genreList, categoryList, workStatusList } = this;
      const { getters } = this.$store;
      const novel = getters["novelModule/novel"](novelId) as Novel;
      this.novel = { ...novel };

      // 保存ボタンのショートカットキーの有効化と解除関数の取得
      this.unbindShortcutKeys = handleShortcutKeys([
        { trigger: TriggerKey.Meta, keys: ["s"], callback: this.submit },
        { trigger: TriggerKey.Ctrl, keys: ["s"], callback: this.submit },
      ]);

      const { genre, category, workStatus, image } = novel;
      if (genre) {
        this.genre = genreList.find((item) => item.id === genre) ?? null;
      }
      if (category) {
        this.category = categoryList.filter((item) => category.includes(item.id));
      }
      if (workStatus) {
        this.workStatus =
          workStatusList.find((x) => x.id === workStatus) ?? workStatusList.find((x) => x.id === "inProgress")!;
      }
      if (image) {
        this.image = image;
      }
      // ポップアップ表示（初回かつ、制作状況が制作完了以外の場合）
      if (!this.hasDisplayedWorkStatusHighlightPopup && this.workStatusIsNotCompleted) {
        this.showFirstWorkStatusHighlight = true;

        // 次回以降ポップアップを非表示にする
        await this.$store.dispatch("generalModule/setDisplayedWorkStatusHighlightPopup", true);
      }
    },
    change() {
      this.setModified(true);
    },
    imagePath() {
      const { novel, novelId } = this;

      if (!novel) {
        return null;
      }

      const { image } = this;
      if (!image) {
        return null;
      }

      if (image.startsWith("file:")) {
        return `file:novels/${novelId}/cover.jpg`;
      }

      if (image.startsWith("preset:")) {
        return image;
      }

      return null;
    },
    async submit() {
      const { novelId, novel, showErrorDialog } = this;

      if (!novel) {
        showErrorDialog("更新する作品が取得できません。");
        return;
      }

      const { title, description } = novel;
      const { genre, category, workStatus, image } = this;
      let genreId;
      let categoryIds;
      let workStatusId;

      if (genre) {
        genreId = genre.id;
      }

      if (category) {
        categoryIds = category.map((x) => x.id);
      }

      if (workStatus) {
        workStatusId = workStatus.id;
      }

      await submitImage(`novels/${novelId}/cover.jpg`);
      const { dispatch } = this.$store;
      await dispatch("novelModule/updateNovel", {
        novelId,
        title,
        image,
        description,
        genre: genreId,
        category: categoryIds,
        workStatus: workStatusId,
      });
      this.setModified(false);
      this.$router.push({ name: "detail", params: { novelId } });

      this.$notify({
        title: "保存しました",
        type: "success",
      });
    },
    selectGenre(item) {
      this.genre = item;
      this.change();
    },
    unselectGenre() {
      this.genre = null;
      this.change();
    },
    selectCategory(item: NovelParam[]) {
      this.category = item.length ? item : null;
      this.change();
    },
    selectWorkStatus(item) {
      this.workStatus = item;
      this.change();
    },
    showErrorDialog(message: string) {
      const confirmDialog = new Dialog(DialogVue);
      const options = {
        title: "エラー",
        content: message,
      };

      confirmDialog.show(options);
    },
    onboardingOutsideClicked() {
      this.showFirstWorkStatusHighlight = false;
    },
  },
});
